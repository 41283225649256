import React from "react";
// import logoLight from '../../assets/images/logo/logo-light.png'
import { Link } from 'react-router-dom'
import './footer.scss'

import Logo from "../../assets/svg/LogoLg.png";

import Subscribe from './Subscribe'

export default function Footer() {
  return (
    <footer className="footer footer-2 footer-4">
      <div className="container">
        <div className="footer-widget">
          <div className="row">

            <div
              className="col-sm-12 col-md-12 col-lg-3 widget--logo text-center-xs"
            >
              <div className="widget--content">
                <div className="widget--logo-img">

                  <img src={Logo} alt="comfeey" />
                  
                </div>

                <div className="footer--copyright">
                  <span>Design and Development 
                    <a href="https://deveb.co/">
                      <b>
                        <svg id="Group_1132" data-name="Group 1132" xmlns="http://www.w3.org/2000/svg" width="42.053" height="26.809" viewBox="0 0 42.053 26.809">
                          <path id="Path_371" data-name="Path 371" d="M-1251.684,1900.141h-8.834a3.207,3.207,0,0,0,1.028,2.225,3.246,3.246,0,0,0,2.267.84,2.8,2.8,0,0,0,2.729-1.615h2.581a5.211,5.211,0,0,1-1.9,2.612,5.575,5.575,0,0,1-3.41,1.017,5.9,5.9,0,0,1-2.969-.745,5.3,5.3,0,0,1-2.056-2.1,6.4,6.4,0,0,1-.745-3.137,6.561,6.561,0,0,1,.724-3.137,5.087,5.087,0,0,1,2.036-2.087,6.051,6.051,0,0,1,3.011-.735,5.888,5.888,0,0,1,2.915.715,5.035,5.035,0,0,1,1.994,2,6.042,6.042,0,0,1,.714,2.969A7.5,7.5,0,0,1-1251.684,1900.141Zm-2.414-1.93a2.727,2.727,0,0,0-.945-2.119,3.377,3.377,0,0,0-2.287-.8,3.076,3.076,0,0,0-2.12.786,3.287,3.287,0,0,0-1.05,2.131Z" transform="translate(1281.214 -1878.414)" fill="#b8bbbf"/>
                          <g id="Group_1130" data-name="Group 1130" transform="translate(31.641 13.458)">
                            <path id="Path_372" data-name="Path 372" d="M-1201.185,1889.736a4.523,4.523,0,0,1,2.072-.47,4.676,4.676,0,0,1,2.433.646,4.531,4.531,0,0,1,1.714,1.833,5.741,5.741,0,0,1,.63,2.717,5.856,5.856,0,0,1-.63,2.736,4.678,4.678,0,0,1-1.723,1.88,4.542,4.542,0,0,1-2.423.673,4.58,4.58,0,0,1-2.091-.461,4.012,4.012,0,0,1-1.446-1.18v1.469h-2.1v-12.4h2.1v3.768A3.989,3.989,0,0,1-1201.185,1889.736Zm4.275,2.912a2.968,2.968,0,0,0-1.143-1.161,3.076,3.076,0,0,0-1.521-.4,2.984,2.984,0,0,0-1.5.406,3.026,3.026,0,0,0-1.143,1.18,3.675,3.675,0,0,0-.431,1.824,3.733,3.733,0,0,0,.431,1.834,3,3,0,0,0,1.143,1.188,2.976,2.976,0,0,0,1.5.406,2.955,2.955,0,0,0,1.521-.415,3.054,3.054,0,0,0,1.143-1.207,3.787,3.787,0,0,0,.433-1.843,3.63,3.63,0,0,0-.433-1.814Z" transform="translate(1204.749 -1887.175)" fill="#b8bbbf"/>
                          </g>
                          <path id="Path_373" data-name="Path 373" d="M-1307.839,1902.471l-3.192-9.187h-2.477l4.3,11.275h2.782l4.277-11.275h-2.5Z" transform="translate(1320.087 -1878.418)" fill="#b8bbbf"/>
                          <path id="Path_374" data-name="Path 374" d="M-1276.752,1839.554h-8.834a3.205,3.205,0,0,0,1.028,2.225,3.242,3.242,0,0,0,2.266.839,2.8,2.8,0,0,0,2.729-1.615h2.581a5.217,5.217,0,0,1-1.9,2.607,5.572,5.572,0,0,1-3.41,1.017,5.9,5.9,0,0,1-2.969-.745,5.292,5.292,0,0,1-2.056-2.1,6.4,6.4,0,0,1-.745-3.137,6.549,6.549,0,0,1,.723-3.137,5.091,5.091,0,0,1,2.035-2.089,6.062,6.062,0,0,1,3.011-.735,5.894,5.894,0,0,1,2.917.715,5.039,5.039,0,0,1,1.994,2,6.036,6.036,0,0,1,.713,2.969A7.482,7.482,0,0,1-1276.752,1839.554Zm-2.414-1.93a2.727,2.727,0,0,0-.945-2.119,3.378,3.378,0,0,0-2.288-.8,3.076,3.076,0,0,0-2.119.786,3.293,3.293,0,0,0-1.049,2.13Z" transform="translate(1300.502 -1831.284)" fill="#b8bbbf"/>
                          <g id="Group_1131" data-name="Group 1131">
                            <path id="Path_375" data-name="Path 375" d="M-1335.207,1829.17a4.529,4.529,0,0,0-2.073-.47,4.673,4.673,0,0,0-2.432.646,4.533,4.533,0,0,0-1.714,1.833,5.749,5.749,0,0,0-.63,2.719,5.857,5.857,0,0,0,.63,2.736,4.682,4.682,0,0,0,1.724,1.88,4.549,4.549,0,0,0,2.423.673,4.589,4.589,0,0,0,2.092-.461,4.021,4.021,0,0,0,1.446-1.18v1.469h2.1v-12.406h-2.1v3.768A3.986,3.986,0,0,0-1335.207,1829.17Zm-4.275,2.913a2.961,2.961,0,0,1,1.142-1.161,3.077,3.077,0,0,1,1.52-.4,2.984,2.984,0,0,1,1.5.406,3.02,3.02,0,0,1,1.143,1.18,3.676,3.676,0,0,1,.432,1.824,3.726,3.726,0,0,1-.432,1.834,2.991,2.991,0,0,1-1.143,1.188,2.977,2.977,0,0,1-1.5.406,2.96,2.96,0,0,1-1.52-.414,3.043,3.043,0,0,1-1.142-1.207,3.782,3.782,0,0,1-.434-1.843A3.623,3.623,0,0,1-1339.481,1832.083Z" transform="translate(1342.056 -1826.609)" fill="#b8bbbf"/>
                          </g>
                        </svg>
                      </b>
                    </a></span> 
                </div>
              </div>

            </div>

            <div className="col-sm-12 col-md-6 col-lg-3 text-center-xs widget--contact-info">
              <div className="widget--content footer-contact-details">
                <ul className="list-unstyled">
                  <li>
                    <a href="mailto:comfeey@gmail.com">support@dopegood.com</a>
                  </li>
                  <li>
                    <a href="tel:+7167049295">+716 704 9295</a>
                  </li>
                  <li> NY 10461 - NEW YORK.</li>
                </ul>
              </div>
            </div>
            {/* .col-lg-5ths end  */}

            <div
              className="col-sm-12 col-md-6 col-lg-3 text-center-xs widget--links"
            >
              <div className="widget--content">
                <ul className="list-unstyled">
                  <li>
                    <Link to="/about">About Us </Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms">Term & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* .col-lg-5ths end  */}

            <Subscribe />
            {/* .col-lg-5ths end  */}

            {/* <div
              className="
                  col-sm-12 col-md-6 col-lg-5ths
                  text-center-xs
                  widget--social
                "
            >
              <div className="widget--content">
                <div className="social--icons">
                  <span>Follow Us On Social</span>
                  <a className="facebook" href="/#">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a className="twitter" href="/#">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a className="google-plus" href="/#">
                    <i className="fa fa-pinterest-p"></i>
                  </a>
                  <a className="instagram" href="/#">
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div> */}
            {/* .col-lg-5ths end  */}

          </div>
          {/* .row end  */}
        </div>
        {/* .container end  */}
      </div>
      {/* .footer-widget end  */}
    </footer>
  );
}
