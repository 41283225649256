import React, { useEffect } from 'react'

import { useSelector, useDispatch } from "react-redux";
import { scrollProxyActivated, scrollProxyDeactivated } from "../store/Scroll/reducer";

import Hero from '../components/About/Hero'
import Gallery from '../components/About/Gallery'
import Info from '../components/About/Info'

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { Helmet } from "react-helmet";
import useSmoothScroll from '../utils/useSmoothScroll';

gsap.registerPlugin(ScrollTrigger)

export default function About() {

	const storeScroller = useSelector((state) => state.scroller.scroller);

	const dispatch = useDispatch();

	// Initialize Smooth Scroller
	useSmoothScroll()

	useEffect( () => {

		if( !storeScroller ) return
		
		ScrollTrigger.scrollerProxy(document.body, {
			scrollTop(value) {
				if (arguments.length) {
					storeScroller.scrollTop = value;
				}
		
				return storeScroller.scrollTop;
			},
		});

		storeScroller.addListener(ScrollTrigger.update);

		dispatch(scrollProxyActivated())

		return () => {
			
			const scrollTriggers = ScrollTrigger.getAll()
			if( scrollTriggers.length ) scrollTriggers.forEach( scrollT => scrollT.kill() )
			dispatch(scrollProxyDeactivated())

		}

	},[storeScroller])

	return (
		<div className="view-wrapper" >

			<Helmet>
				<title>About DopeGood</title>
				<meta name="description" 
				content="Since our online store was launched, we have thrilled interior designers and home decor enthusiasts all across the country and around the world." />
      </Helmet>

			<Hero />

			<Gallery />

			<Info />

		</div>
	)
}
