import { useEffect } from 'react';
import SmoothScrollbar from "smooth-scrollbar";
import SoftScroll from "./SoftScroll";
import { useSelector, useDispatch } from "react-redux";
import { setScroller, destroyScroller } from "../store/Scroll/reducer";
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
// import gsap from "gsap";
// import ScrollTrigger from 'gsap/ScrollTrigger';


// gsap.registerPlugin(ScrollTrigger)

export default function useSmoothScroll() {

  const storeScroller = useSelector((state) => state.scroller.scroller);

	const dispatch = useDispatch();

  useEffect(() => {

    if( window.innerWidth > 768 ) initializeSmoothScroll()
    // setSmoothScrollBar(Scrollbar)

    return () => {
      if( storeScroller && storeScroller.destory ) {
        storeScroller.destory()
        dispatch( destroyScroller() )
      }
    }
  }, [storeScroller]);

  const initializeSmoothScroll = () => {

    SmoothScrollbar.use( SoftScroll, OverscrollPlugin );

    // Init smooth scrollbar
    const view = document.querySelector(".app-wrapper");

    const Scrollbar = SmoothScrollbar.init(view, {
      renderByPixels: true,
      damping: 0.09,
      continuousScrolling: false,
      plugins: {
        overscroll: {
          effect: 'glow',
          damping: 0.09,
          glowColor: '#222a2d',
          maxOverScroll: 150
        }
      }
    });

    Scrollbar.setPosition(0, 0);
    Scrollbar.track.xAxis.element.remove()

    view.classList.add('smooth-scroll')
    document.documentElement.style.height = "100%";
    document.documentElement.style.overflow = "hidden";
    document.body.classList.add('no-scrollbar')

    dispatch( setScroller( Scrollbar ) )
  }

  return null;
}