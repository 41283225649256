import React from 'react'

import Hero from '../components/Profile/Hero'
import ProfileTabsWrapper from '../components/Profile/ProfileTabsWrapper'

import { Helmet } from "react-helmet";
import useSmoothScroll from '../utils/useSmoothScroll';

export default function Profile() {

  // Initialize Smooth Scroller
	useSmoothScroll()

  return (
    <div className="view-wrapper">

      <Helmet>
				<title>Profile</title>
				<meta name="description" 
				content="View your account details and change any personal data." />
      </Helmet>

      <Hero />

      <ProfileTabsWrapper />
      
    </div>
  )
}
