import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'scroller',
  initialState: {
    scrollProxySet: false,
    scroller: null,
  },
  reducers: {
    setScroller: ( state, action ) => {
      
      state.scroller = action.payload 
    
    },
    scrollProxyActivated: ( state ) => {
      state.scrollProxySet = true
    },
    scrollProxyDeactivated: ( state ) => {
      state.scrollProxySet = false
    },
    destroyScroller: ( state ) => {
      state.scroller = null
      state.isSmooth = false
    }
  },
})

export const { setScroller, destroyScroller, scrollProxyActivated, scrollProxyDeactivated } = 
  counterSlice.actions

export default counterSlice.reducer