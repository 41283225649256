import React from 'react'
import Title from '../components/Login/ForgotPass/Title'
import ForgotForm from '../components/Login/ForgotPass/ForgotForm'
import useSmoothScroll from '../utils/useSmoothScroll'

export default function ForgotPass() {

	// Initialize Smooth Scroller
	useSmoothScroll()

	return (
		<div className="view-wrapper" >

			<Title />
			
			<ForgotForm />

		</div>
	)
}
