import React from 'react'
import Title from '../components/Privacy/Title'
import Content from '../components/Privacy/Content'

import { Helmet } from "react-helmet";
import useSmoothScroll from '../utils/useSmoothScroll';

export default function Privacy() {
	
	// Initialize Smooth Scroller
	useSmoothScroll()

	return (
		<div className="view-wrapper" >
			
			<Helmet>
				<title>Privacy</title>
      </Helmet>

			<Title />

			<Content />

		</div>
	)
}
