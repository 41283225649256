import { configureStore } from '@reduxjs/toolkit'
import productsReducer from './Shop/reducer'
import scrollReducer from './Scroll/reducer'

export default configureStore({
  reducer: {
		shop: productsReducer,
		scroller: scrollReducer
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
})